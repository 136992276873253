@font-face {
  font-family: "Candara";
  src: url("/public/fonts/candara.ttf");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* For Chrome, Safari, and Opera */
*::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  background-color: transparent;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
}
